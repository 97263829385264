<template>
  <div>
    <Home :selectedPageTemplate="selectedPageTemplate" />
  </div>
</template>

<script>
  export default {
    components: {
      Home: () => import('@/components/Design/View/ViewTheme'),
    },
    data: () => ({
      selectedPageTemplate: 'Home',
    }),
    created () {
    }
  }
</script>
